<template>
  <div class="addGroupBuy">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>添加/编辑拼团</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form ref="form" label-width="80px">
      <el-form-item label="产品">
        <el-button type="primary" @click="dialogVisible = true">选择产品</el-button>
      </el-form-item>
      <el-form-item label="" class="chanpin" v-show="formList.prodType">
        <table
          border="1"
          style="border-radius: 5px; border: 1px solid #dcdfe6; color: #606266"
          cellSpacing="0px"
        >
          <tr>
            <td style="border: 1px; text-align: center; padding-left: 10px">产品名称</td>
            <td style="border: 1px; text-align: center; width: 250px">原价</td>
            <td style="border: 1px; text-align: center; width: 250px">团购价</td>
            <td style="border: 1px; text-align: center">操作</td>
          </tr>
          <tr style="height: 60px">
            <td style="border: 1px; text-align: center; padding-left: 10px">
              <span>{{ tableData[0].name }}</span>
            </td>
            <td style="border: 1px; text-align: center">
              <el-input
                style="width: 200px"
                @change="onChange"
                type="number"
                v-model="formList.oldPrice"
                placeholder="请输入原价"
              ></el-input>
            </td>
            <td style="border: 1px; text-align: center">
              <el-input
                style="width: 200px"
                @change="onChange"
                type="number"
                v-model="formList.price"
                placeholder="请输入拼团价"
              ></el-input>
            </td>
            <td style="border: 1px; width: 100px; text-align: center">
              <el-button type="primary" @click="onquxiao">取消</el-button>
            </td>
          </tr>
        </table>
      </el-form-item>
      <el-form-item label="活动名称">
        <el-input v-model="formList.groupBuyName" placeholder="请输入活动名称"></el-input>
      </el-form-item>
      <el-form-item label="分享标题">
        <el-input v-model="formList.shareTitle" placeholder="请输入分享标题"></el-input>
      </el-form-item>
      <el-form-item label="游戏ID">
        <el-input
          v-model="formList.gameId"
          placeholder="购票成功后是否参与游戏活动  0 为不参与"
        ></el-input>
      </el-form-item>
      <el-form-item label="活动图片">
        <send-image
          :images="formList.imgUrl"
          v-if="Number(id) == 0 || (Number(id) > 0 && formList.groupBuyName !== '')"
          @addImageStr="addImageStr"
        ></send-image>
      </el-form-item>
      <el-form-item
        ><span style="color: red">请选择图片,推荐尺寸750*500px</span></el-form-item
      >
      <el-form-item label="活动开始时间">
        <el-date-picker
          v-model="formList.beginTime"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="请选择活动开始时间"
        >
        </el-date-picker>
        -
        <el-date-picker
          v-model="formList.endTime"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="请选择活动结束时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="" style="height: 50px; margin: 0"
        ><span style="color: red">活动的销售时间</span></el-form-item
      >

      <el-form-item label="成团人数" style="margin-top: 0px">
        <el-input
          @input="onInput"
          v-model="formList.peopleCount"
          placeholder="请输入成团人数"
        ></el-input>
      </el-form-item>
      <el-form-item label="活动标签">
        <el-input v-model="formList.label" placeholder="请输入活动标签"></el-input>
      </el-form-item>
      <el-form-item label="产品使用时间">
        <el-date-picker
          v-model="formList.useBeginTime"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="请选择活动开始时间"
        >
        </el-date-picker>
        -
        <el-date-picker
          v-model="formList.useEndTime"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="请选择活动结束时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="" style="height: 50px; margin: 0"
        ><span style="color: red"
          >客户购买该产品后的预约使用时间只有在这个时间段使用</span
        ></el-form-item
      >

      <el-form-item label="限购数量" style="margin-top: 0px">
        <el-input
          @input="onInput"
          v-model="formList.quotaCount"
          placeholder="请输入限购数量"
        ></el-input>
      </el-form-item>
      <el-form-item label="" style="height: 50px; margin: 0"
        ><span style="color: red"
          >一个客户可购买的数量:0表示不限制,1表示可以购买一次,2表示可以购买两次,以此类推</span
        ></el-form-item
      >
      <el-form-item label="活动数量" style="margin-top: 0px">
        <el-input
          @input="onInput"
          v-model="formList.groupBuyCount"
          placeholder="请输入活动数量数量"
        ></el-input>
      </el-form-item>
      <el-form-item label="" style="height: 50px; margin: 0"
        ><span style="color: red">参加活动的总数量,该活动销售多少份</span></el-form-item
      >
      <el-form-item label=" 提前预定天数" style="margin-top: 0px">
        <el-input
          v-model.number="formList.advanceDay"
          placeholder="请输入提前预定天数"
        ></el-input>
      </el-form-item>
      <el-form-item label="" style="height: 50px; margin: 0"
        ><span style="color: red">0表示不限制</span></el-form-item
      >

      <el-form-item label="限定身份证"   style="margin-top: 0px; display: flex; align-items: center">
      <div >
        <el-radio v-model="formList.cardType" :label="0">不限定</el-radio>
        <el-radio v-model="formList.cardType" :label="1"
          >限定开团人身份证</el-radio
        >
        <el-radio v-model="formList.cardType" :label="2"
          >限定使用人身份证</el-radio
        >
      </div>
      </el-form-item>
      <el-form-item style="margin-top: 0px" label="温馨提示" v-if="formList.cardType">
        <el-input
        style="width: 40%;"
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 6 }"
          placeholder="请输入内容"
          v-model="formList.cardTip"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="自动成功" style="margin-top: 0px" class="unflex">
        <el-radio v-model="radio" label="1">是</el-radio>
        <el-radio v-model="radio" label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="是否改期" style="margin-top: 0px" class="unflex">
        <el-radio v-model="formList.changeInfo" :label="1">是</el-radio>
        <el-radio v-model="formList.changeInfo" :label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="是否显示" style="margin-top: 0px" class="unflex">
        <el-radio v-model="formList.isShow" :label="1">是</el-radio>
        <el-radio v-model="formList.isShow" :label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="万旅网是否显示" style="margin-top: 0px" class="unflex">
        <el-radio v-model="formList.wlShow" :label="1">是</el-radio>
        <el-radio v-model="formList.wlShow" :label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="是否仅限新用户购买">
        <el-radio v-model="formList.isNew" :label="1">是</el-radio>
        <el-radio v-model="formList.isNew" :label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="拼团时间（分钟）">
        <el-input-number
          v-model="formList.waitTime"
          :step="1"
          :min="3"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="" style="height: 50px; margin: 0"
        ><span style="color: red"
          >团长开团后有效期。截止该有效期该团如果支持自动成功 则成功
          不支持则系统取消该团并退款</span
        ></el-form-item
      >
      <el-form-item label="活动说明" class="col">
        <!-- <quill-editor
          class="richEdit"
          v-model="formList.remark"
          :options="quillOption"
        >
        </quill-editor> -->
        <VueUeditorWrap v-model="formList.remark" :config="myConfig"></VueUeditorWrap>
      </el-form-item>
      <el-form-item class="col" style="margin-top: 120px">
        <div class="btn">
          <el-button type="primary" @click="onSave">保存</el-button>
          <el-button type="primary" @click="$router.go(-1)">返回</el-button>
        </div>
      </el-form-item>
    </el-form>

    <el-dialog title="选择产品" :visible.sync="dialogVisible" width="60%">
      <select-products @addData="onAddData"></select-products>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { Quill } from "vue-quill-editor";
import quillConfig from "../../../../quill-config";
import selectProducts from "./selectProducts";
import { addOrEditGroupBuy, groupBuyDetail } from "../../../api/groupBuy";
import { roomDetails } from "../../../api/room";
import { ticketDetails } from "../../../api/ticket";
import { travelSpecDetails } from "../../../api/travelSpecs";
import { sendFile } from "../../../api/sendFile";
import SendImage from "../../../components/sendImage.vue";
import VueUeditorWrap from "vue-ueditor-wrap";
export default {
  name: "addGroupBuy",
  components: {
    selectProducts,
    SendImage,
    VueUeditorWrap,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    addid: {
      type: [Number, String],
    },
    type: {
      type: [Number, String],
    },
  },
  data() {
    return {
      myConfig: {
        toolbars: [
          [
            "simpleupload", //单图上传
            "fullscreen",
            "source",
            "|",
            "undo",
            "redo",
            "|",
            "bold",
            "italic",
            "underline",
            "fontborder",
            "strikethrough",
            "superscript",
            "subscript",
            "removeformat",
            "formatmatch",
            "autotypeset",
            "blockquote",
            "pasteplain",
            "|",
            "forecolor",
            "backcolor",
            "insertorderedlist",
            "insertunorderedlist",
            "selectall",
            "|",
            "rowspacingtop",
            "rowspacingbottom",
            "lineheight",
            "|",
            "customstyle",
            "paragraph",
            "fontfamily",
            "fontsize",
            "|",
            "directionalityltr",
            "directionalityrtl",
            "indent",
            "|",
            "justifyleft",
            "justifycenter",
            "justifyright",
            "justifyjustify",
            "|",
            "touppercase",
            "tolowercase",
            "|",
            "imagenone",
            "imageleft",
            "imageright",
            "imagecenter",
            "|",
            "emotion",
            "map",
            "insertcode",
            "background",
            "|",
            "horizontal",
            "date",
            "time",
            "spechars",
            "|",
            "inserttable",
            "deletetable",
            "insertparagraphbeforetable",
            "insertrow",
            "deleterow",
            "insertcol",
            "deletecol",
            "mergecells",
            "mergeright",
            "mergedown",
            "|",
            "print",
            "preview",
          ],
        ],
        autoHeightEnabled: false, // 编辑器不自动被内容撑高
        initialFrameHeight: 600, // 初始容器高度
        initialFrameWidth: "50%", // 初始容器宽度
        serverUrl: "/config",
        UEDITOR_HOME_URL: "/static/UEditor/",
        maximumWords: "100000000",
      },
      quillOption: quillConfig,
      dialogVisible: false,
      tableData: [
        {
          name: "",
          oldPrice: "",
          price: "",
        },
      ],
      radio: "1",
      formList: {
        groupBuyName: "",
        beginTime: "",
        endTime: "",
        peopleCount: null,
        quotaCount: null,
        useBeginTime: "",
        useEndTime: "",
        label: "",
        groupBuyCount: null,
        prodType: null,
        prodId: null,
        oldPrice: null,
        price: null,
        imgUrl: "",
        gameId: "",
        remark: "",
        waitTime: "",
        changeInfo: 1,
        isShow: 1,
        advanceDay: 0,
        shareTitle: "",
        wlShow:1,
        cardType: 0,
        cardTip: "",
        isNew:0
      },
      isSuccess: 1,
    };
  },
  watch: {
    radio: function () {
      this.isSuccess = Number(this.radio);
    },
  },
  mounted() {
    quillConfig.register(Quill);
    quillConfig.initButton();
  },
  created() {
    if (Number(this.id) != 0) {
      this.getgroupBuyDetail();
    }
    if (Number(this.id) == 0 && Number(this.type) == 1 && Number(this.addid) !== 0) {
      this.getroomDetails();
    } else if (
      Number(this.id) == 0 &&
      Number(this.type) == 2 &&
      Number(this.addid) !== 0
    ) {
      this.getticketDetails();
    } else if (
      Number(this.id) == 0 &&
      Number(this.type) == 3 &&
      Number(this.addid) !== 0
    ) {
      this.gettravelSpecDetails();
    }
  },
  methods: {
    addImageStr(image) {
      this.formList.imgUrl = image;
    },

    async getroomDetails() {
      const { data } = await roomDetails({
        roomId: Number(this.addid),
      });
      this.formList.prodType = Number(this.type);
      this.tableData[0].name = data.data[0].roomName;
      this.formList.prodId = Number(this.addid);
    },
    async getticketDetails() {
      const { data } = await ticketDetails({
        ticketId: Number(this.addid),
      });
      this.formList.prodType = Number(this.type);
      this.tableData[0].name = data.data[0].ticketName;
      this.formList.prodId = Number(this.addid);
    },
    async gettravelSpecDetails() {
      const { data } = await travelSpecDetails({
        specsId: Number(this.addid),
      });
      this.formList.prodType = Number(this.type);
      this.tableData[0].name = data.data[0].specName;
      this.formList.prodId = Number(this.addid);
    },
    async getgroupBuyDetail() {
      const { data } = await groupBuyDetail({
        groupBuyId: Number(this.id),
      });
      this.formList.groupBuyName = data.data[0].groupBuyName;
      this.formList.beginTime = data.data[0].beginTime;
      this.formList.endTime = data.data[0].endTime;
      this.formList.peopleCount = data.data[0].peopleCount;
      this.formList.useBeginTime = data.data[0].useBeginTime;
      this.formList.useEndTime = data.data[0].useEndTime;
      this.formList.label = data.data[0].label;
      this.formList.groupBuyCount = data.data[0].groupBuyCount;
      this.formList.quotaCount = data.data[0].quotaCount;
      this.formList.prodType = data.data[0].prodType;
      this.formList.prodId = data.data[0].prodId;
      this.formList.oldPrice = data.data[0].oldPrice;
      this.formList.price = data.data[0].price;
      this.formList.imgUrl = data.data[0].imgUrl;
      this.formList.remark = data.data[0].remark;
      this.formList.gameId = data.data[0].gameId;
      this.formList.waitTime = data.data[0].waitTime;
      this.formList.changeInfo = data.data[0].changeInfo;
      this.formList.isShow = data.data[0].isShow;
      this.formList.advanceDay = data.data[0].advanceDay;
      this.formList.shareTitle = data.data[0].shareTitle;
      this.formList.wlShow = data.data[0].wlShow || 1;
      this.formList.isNew = data.data[0].isNew || 0;
      this.formList.cardTip = data.data[0].cardTip;
      this.formList.cardType = data.data[0].cardType;
      this.isSuccess = data.data[0].isSuccess;
      this.radio = data.data[0].isSuccess + "";
      this.tableData[0].name = data.data[0].prodName;

      console.log(data);
    },
    onInput(value) {
      var reg = /^[0-9]*[1-9][0-9]*$/;
      if (!reg.test(value)) {
        return this.$message({
          type: "error",
          message: "请输入整数!",
        });
      }
    },
    onChange(value) {
      var reg = /^(0\.?\d{0,5}|[1-9]\d*\.?\d{0,5})$/;
      if (!reg.test(value)) {
        return this.$message({
          type: "error",
          message: "请输入非负数!",
        });
      }
    },
    async onSave() {
      let params = JSON.parse(JSON.stringify(this.formList))
      if(!this.formList.cardType){
       delete params.cardTip
      }
      for (const key in params) {
        
        if (params[key] === null || params[key] === "") {
          return this.$message.error("请把数据填写完整");
        }
      }
      const { data } = await addOrEditGroupBuy({
        ...this.formList,
        isSuccess: this.isSuccess,
        groupBuyId: Number(this.id),
      });
      if (data.code == 0) {
        this.$message.success(data.msg);
        this.$router.go(-1);
      } else {
        this.$message.error(data.msg);
      }
    },
    async onAddData(obj, type) {
      console.log(obj, type);
      this.formList.prodType = type;
      this.tableData[0].name = obj.name;
      this.formList.prodId = obj.id;
      this.dialogVisible = false;
    },
    onquxiao() {
      this.formList.prodType = null;
      this.tableData[0].name = "";
      this.formList.prodId = null;
    },
  },
};
</script>
<style lang="less" scoped>
.addGroupBuy {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  /deep/.unflex {
    display: flex;
    .el-form-item__content {
      display: unset !important;
    }
  }
  /deep/.el-form-item {
    margin-top: 50px;
    width: 1000px;
    .el-form-item__label {
      width: 120px !important;
    }
    .el-form-item__content {
      margin-left: 120px !important;
      display: flex;
      .img {
        position: relative;
        height: 100px;
        width: 100px;
        margin: 20px 20px;
        img {
          height: 100px;
          width: 100px;
          border: 1px solid #c0ccda;
        }
        div {
          position: absolute;
          top: 0;
          right: 2px;
          height: 10px;
          width: 10px;
          z-index: 100;
        }
      }
      .addImg {
        display: inline-block;
        width: 100px;
        height: 100px;
        border: 1px dashed #c0ccda;
        text-align: center;
        line-height: 100px;
        cursor: pointer;
        margin-top: 20px;
        i {
          width: 50px;
          height: 50px;
          font-size: 20px;
          color: #c0ccda;
        }
      }

      // .el-input__inner {
      //   width: 600px;
      // }
      .el-date-editor {
        width: 292px !important;
      }
    }
  }
  /deep/ .chanpin {
    .el-form-item__content {
      .el-table {
        .el-table__body-wrapper {
          tbody {
            td {
              .el-input {
                width: 100% !important;
              }
            }
          }
        }
      }
    }
  }
}
.richEdit {
  width: 600px;
  height: 600px;
  .ql-container {
    height: 400px;
  }
}
</style>
