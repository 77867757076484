import baseUrl from './src/api/baseUrl'
const uploadConfig = {
    action: baseUrl + '/upload/fileUpload',
    methods: 'POST',
    token: '',
    name: 'file',
    size: 500,
    accept: 'image/png'
};

const toolOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ 'header': 1 }, { 'header': 2 }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'direction': 'rtl' }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['clean'],
    ['link', 'image', 'video'],
    ['sourceEditor']
];
const handlers = {
    shadeBox: null,
    sourceEditor: function () {
        const reg = /\<br\>/g,
            container = this.container,
            firstChild = container.nextElementSibling.firstChild;
        if (!this.shadeBox) {
            let shadeBox = this.shadeBox = document.createElement('div');
            shadeBox.style.cssText = 'position:absolute; top:0; left:0; width:100%; height:100%; background:rgba(0,0,0,0.5); cursor:pointer';
            container.style.position = 'relative';
            shadeBox.addEventListener('click', function () {
                this.style.display = 'none';

                firstChild.innerHTML = firstChild.innerText;
            }, false);
            container.appendChild(shadeBox);
            let innerHTML = firstChild.innerHTML;

            firstChild.innerText = innerHTML;
        } else {
            let innerHTML = firstChild.innerHTML;
            firstChild.innerText = innerHTML;
            this.shadeBox.style.display = 'block';
        }

    },
    image: function image() {
        var self = this;

        var fileInput = this.container.querySelector('input.ql-image[type=file]');
        if (fileInput === null) {
            fileInput = document.createElement('input');
            fileInput.setAttribute('type', 'file');
            if (uploadConfig.name) {
                fileInput.setAttribute('name', uploadConfig.name);
            }
            fileInput.setAttribute('accept', uploadConfig.accept);
            fileInput.classList.add('ql-image');
            fileInput.addEventListener('change', function () {
                var formData = new FormData();
                formData.append(uploadConfig.name, fileInput.files[0]);
                if (uploadConfig.token) {
                    formData.append('token', uploadConfig.token)
                }
                var xhr = new XMLHttpRequest();
                xhr.open(uploadConfig.methods, uploadConfig.action, true);
                xhr.onload = function (e) {
                    if (xhr.status === 200) {
                        var res = JSON.parse(xhr.responseText);
                        let length = self.quill.getSelection(true).index;
                        self.quill.insertEmbed(length, 'image', res.data[0].src);
                        self.quill.setSelection(length + 1)
                    }
                    fileInput.value = ''
                };
                xhr.upload.onloadstart = function (e) {
                    fileInput.value = ''
                };
                xhr.upload.onerror = function (e) {
                };
                xhr.upload.onloadend = function (e) {
                };
                xhr.send(formData)
            });
            this.container.appendChild(fileInput);
        }
        fileInput.click();
    }
};

export default {
    placeholder: '',
    theme: 'snow',
    modules: {
        toolbar: {
            container: toolOptions,
            handlers: handlers
        }
    },
    initButton: function () {
        const sourceEditorButton = document.querySelectorAll('.ql-sourceEditor');
        for (let index = 0; index < sourceEditorButton.length; index++) {
            sourceEditorButton[index].style.cssText = "width:80px; border:1px solid #ccc; border-radius:5px;";
            sourceEditorButton[index].innerText = "源码编辑";
            sourceEditorButton[index].classList.add('el-icon-edit-outline');
            sourceEditorButton[index].title = "源码编辑";
        }
    },
    register(q) {
        class div extends q.import('blots/block/embed') { }
        class table extends q.import('blots/block/embed') { }
        class tr extends q.import('blots/block/embed') { }
        class td extends q.import('blots/block/embed') { }
        div.blotName = div.tagName = 'div';
        table.blotName = table.tagName = 'table';
        tr.blotName = tr.tagName = 'tr';
        td.blotName = td.tagName = 'td';
        q.register(div);
        q.register(table);
        q.register(tr);
        q.register(td);
    },


};